import styled from '@emotion/styled'
import { memo } from 'react'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { David0705_BottomMenu } from './david0705_BottomMeun'

export const David0705_Footer = memo<ReactProps>(function David0705_Footer(props) {
  return (
    <styleds.container>
      期貨交易有一定風險 交易人應先評估本身資金及能擔負之風險
      {/* <David0705_BottomMenu /> */}
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_horizontal_all_center};
    background-color: #252525;
    font-size: 14px;
  `,
}
